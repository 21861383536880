<template>
  <div class="createNewsItem">
    <LSection class="mt-36">
      <template #body>
        <form
            @submit.prevent="onSubmit"
            ref="form"
            class="mx-auto shadow-2xl p-6 rounded max-w-2xl"
        >
          <h1 class="text-3xl text-gray-800 font-bold text-center mb-6">
            Create a News Item
          </h1>
          <div class="flex flex-wrap p-6">
            <div class="w-full mb-6 w-50">
              <label for="title">Title</label>
              <TextInput
                  :tabIndex="1"
                  name="title"
                  placeholder="Title"
                  @change="onTitleChange"
              ></TextInput>
            </div>

            <Markdown
                :modelValue="newDocument.body"
                :tabIndex="10"
                :isEditable="true"
                name="body"
                placeholder="News body"
                @change="onBodyChange"
            ></Markdown>


            <div class="w-full mb-6">
              <ImageUrlInput
                  v-model="newDocument.imageUrl"
                  :tabIndex="2"
                  @input="onImageUrlChange"
                  @change="onImageUrlChange"
              ></ImageUrlInput>
            </div>
            <div class="w-full mb-6">
              <label for="description">Landing page card text</label>
              <TextArea
                  :tabIndex="3"
                  name="description"
                  placeholder="A very short description of the news item (~25 words)"
                  @change="onDescriptionChange"
              ></TextArea>
            </div>
            <div class="flex gap-4 w-full mb-6">
              <Button
                  class="w-32"
                  type="submit"
                  :is-primary="true"
                  @click="onSubmit"
              >
                <template #center> Create</template>
              </Button>

              <Button
                  class="button secondary"
                  :is-primary="false"
                  @click="$router.go(-1)"
              >
                <template #center> Cancel</template>
              </Button>
            </div>
          </div>
        </form>
      </template>
    </LSection>
  </div>
</template>

<script>
import {useDocuments} from "@/composables/useDocuments";
import LSection from "@/components/layout/LSection";
import TextInput from "@/components/forms/TextInput";
import TextArea from "@/components/forms/TextArea";
import {ref} from "vue";
import Button from "../forms/Button.vue";
import ImageUrlInput from "@/components/forms/ImageUrlInput";
import {useRouter} from "vue-router";
import Markdown from "@/components/forms/Markdown.vue";

const getDefaultNewsItem = () =>
    new Object({
      //   tags: []
    });

export default {
  name: "CreateNewsItem",
  components: {
    Markdown,
    Button,
    ImageUrlInput,
    LSection,
    TextInput,
    TextArea,
  },
  setup() {
    const newDocument = ref(getDefaultNewsItem());
    const {createDocument} = useDocuments("News");
    const router = useRouter();
    const today = new Date().toISOString();

    const onSubmit = async () => {
      try {
        newDocument.value.date = today;
        newDocument.value.title = newDocument.value.title || 'News Article'
        newDocument.value.description = newDocument.value.description || '...'
        newDocument.value.body = newDocument.value.body || '<br>'
        await createDocument(newDocument.value)
            .then(() => {
              newDocument.value = getDefaultNewsItem();
              router.push("/news");
            })
      } catch (err) {
        console.log(2, 'error creating news item', err)
      }
    };

    return {
      createDocument,
      newDocument,
      onDescriptionChange: (value) => (newDocument.value.description = value),
      onImageUrlChange: (value) => (newDocument.value.imageUrl = value),
      onTitleChange: (value) => (newDocument.value.title = value),
      onBodyChange: (value) => (newDocument.value.body = value),
      onSubmit,
      today,
    };
  },
};
</script>

<style scoped></style>
